// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-landing-page-digital-fractures-js": () => import("./../../../src/templates/landing-page-digital-fractures.js" /* webpackChunkName: "component---src-templates-landing-page-digital-fractures-js" */),
  "component---src-templates-landing-page-group-subscriptions-js": () => import("./../../../src/templates/landing-page-group-subscriptions.js" /* webpackChunkName: "component---src-templates-landing-page-group-subscriptions-js" */),
  "component---src-templates-landing-page-partner-js": () => import("./../../../src/templates/landing-page-partner.js" /* webpackChunkName: "component---src-templates-landing-page-partner-js" */),
  "component---src-templates-landing-page-subscriber-benefits-js": () => import("./../../../src/templates/landing-page-subscriber-benefits.js" /* webpackChunkName: "component---src-templates-landing-page-subscriber-benefits-js" */)
}

